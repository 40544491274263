#main-loader {
    width: 100%;
    height: 100%;
    margin: 0;
    background-color: #3f628a;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
}
#loader {
    width: 8rem;
    height: 8rem;
    border-right: 4px solid rgb(0, 204, 255);
    border-radius: 100%;
    animation: spinRight 800ms linear infinite;
}
#loader:before, #loader:after {
    content: '';
    width: 6rem;
    height: 6rem;
    display: block;
    position: absolute;
    top: calc(50% - 3vmax);
    left: calc(50% - 3vmax);
    border-left: 3px solid rgb(146, 99, 255);
    border-radius: 100%;
    animation: spinLeft 800ms linear infinite;
}
#loader:after {
    width: 4vmax;
    height: 4vmax;
    top: calc(50% - 2vmax);
    left: calc(50% - 2vmax);
    border: 0;
    border-right: 2px solid rgb(90, 101, 255);
    animation: none;
}
@keyframes spinLeft {
    from {
        transform: rotate(0deg);
   }
    to {
        transform: rotate(720deg);
   }
}
@keyframes spinRight {
    from {
        transform: rotate(360deg);
   }
    to {
        transform: rotate(0deg);
   }
}
